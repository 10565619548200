export const stepName = (step) => {
    switch (step) {
        case 1:
            return 'SHAPE';
            break;
        case 2:
            return 'WOOD ORIGIN';
            break;
        case 3:
            return 'WOOD GRAIN';
            break;
        case 4:
            return 'SEASONING';
            break;
        case 5:
            return 'TOASTING';
            break;
        case 6:
            return 'HEAD TOASTING';
            break;
        case 7:
            return 'FINISHING';
            break;
        default:
            console.log('Error');
    }
}
