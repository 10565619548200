export const authenticate = (password) => {
    window.sessionStorage.setItem("password", password);
};

export const isAuthenticated = () => {
    if (window.sessionStorage.getItem("password") !== null) {
        return true;
    }

    return false;
};

export const getPassword = () => {
    return window.sessionStorage.getItem("password");
}

export const logout = () => {
    if (window.sessionStorage.getItem("password") !== null) {
        window.sessionStorage.removeItem("password");
    }
};
