import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Login from '../pages/Login/Login';
import BarrelConceptor from '../pages/BarrelConceptor/BarrelConceptor';
import { isAuthenticated } from './AuthService';

export default function RouteWrapper({
    path,
    component: Component,
    isPrivate,
    ...rest
}) {
    const signed = isAuthenticated();

    /**
    * Redirect user to SignIn page if he tries to access a private route
    * without authentication.
    */
    if (isPrivate && !signed) {
        return <Redirect to="/" />;
    }

    /**
    * Redirect user to Main page if he tries to access a non private route
    * (SignIn or SignUp) after being authenticated.
    */
    if (!isPrivate && signed) {
        return <Redirect to="/barrel-conceptor" />;
    }

    /**
    * If not included on both previous cases, redirect user to the desired route.
    */
    return <Route {...rest} component={Component} />;
}
