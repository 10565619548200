//For local development
//export const URL = 'http://127.0.0.1:8000/api';

//For production
export const URL = 'https://designmybarrel.demptos.fr/api/public/api';

export const addUser = async (data) => {
    await fetch(URL + '/signin', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => {
            if (response.status === 200) {
                alert('User added');
            } else {
                alert('There is an error');
            }
        })
        .catch(error => alert('There is an error'));
}

export const updateUser = async (data) => {
    await fetch(URL + '/update', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => {
            if (response.status === 200) {
                alert('User updated');
            } else {
                alert('There is an error');
            }
        })
        .catch(error => alert('There is an error'));
}

export const deleteUser = async (data) => {
    await fetch(URL + '/delete', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => {
            if (response.status === 200) {
                alert('User deleted');
            } else {
                alert('There is an error');
            }
        })
        .catch(error => alert('There is an error'));
}
