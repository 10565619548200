import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login/Login';
import BarrelConceptor from '../pages/BarrelConceptor/BarrelConceptor';
import Admin from '../pages/Admin/Admin';

export default function Routes() {
    return (
        <Switch>
            <Route path="/barrel-conceptor" component={BarrelConceptor} isPrivate />
            <Route path="/admin" exact component={Admin} />
            <Route path="/" component={Login} />
        </Switch>
    );
}
