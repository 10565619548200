import React from 'react';
import DemptosLogoWhite from '../../assets/images/logo_demptos_blanc.png';

const DemptosLogo = (props) => {
    const { color, isPortrait } = props;

    if (color === 'white') {
        return (
            <img
                style={{
                    position: 'absolute',
                    top: isPortrait ? '2em' : null,
                    bottom: isPortrait ? null : '3.5em',
                    height: isPortrait ? '3.5em' : '4.4em',
                    width: 'auto',
                }}
                src={DemptosLogoWhite}
                alt="Demptos"
            />
        )
    }
}

export default DemptosLogo;
