import React from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const WoodOrigine = (props) => {
    const { step, shape, param, setParam, backgroundColor } = props;

    const infos = [
        {
            value: 'FRENCH OAK',
            info: <html style={{color: 'white'}}>
                Structure impact with <label style={{fontFamily: 'SourceSansPro-Bold'}}>high ellagitannin
                content</label> and <label style={{fontFamily: 'SourceSansPro-Bold'}}>aroma complexity</label>. A balanced
                expression of <label style={{fontFamily: 'SourceSansPro-Bold'}}>vanilla, coconut and clove
                nuances</label> completed by a variety of <label style={{fontFamily: 'SourceSansPro-Bold'}}>roasted and
                spicy aromas</label>. Diversity of colour tones.
            </html>
        },
        {
            value: 'AMERICAN OAK',
            info: <html style={{color: 'white'}}>
                Lowest level of ellagitannins and highest level of vanillin and β-whisky
                lactones. <label style={{fontFamily: 'SourceSansPro-Bold'}}>Strong aroma impact</label>, coconut, spicy,
                sweetness. <label style={{fontFamily: 'SourceSansPro-Bold'}}>A pale-yellow colour</label> to the spirit.
            </html>
        },
        {
            value: 'HUNGARIAN OAK',
            info: <html style={{color: 'white'}}>
                <label style={{fontFamily: 'SourceSansPro-Bold'}}>Medium level</label> of ellagitannins. Rich
                in <label style={{fontFamily: 'SourceSansPro-Bold'}}>eugenol and vanillin</label>. Subtle
                <label style={{fontFamily: 'SourceSansPro-Bold'}}> clove and vanilla notes</label>. A balanced palette of
                <label style={{fontFamily: 'SourceSansPro-Bold'}}> yellow tones</label>, from coppery to deep gold.
            </html>
        },
    ];

    return (
        <div id={styles.container}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title} style={{marginBottom: '1em'}}>WOOD ORIGIN</p>

            {infos.map((info, key) => {
                if (shape === '500 L' && info.value === 'AMERICAN OAK') {
                    return
                } else {
                    return (
                        <Fade key={key} delay={300 + key * 100}>
                            <ParamButton
                                style={param === info.value ? {backgroundColor: 'white', color: '#C8BB9E'} : null}
                                backgroundColor={backgroundColor}
                                text={info.value}
                                info={info.info}
                                selected={param === info.value}
                                onPress={() => setParam('woodOrigine', info.value)}
                            />
                        </Fade>
                    )
                }
            })}
        </div>
    );
};

export default WoodOrigine;
