import React, { useState, useEffect } from 'react';

import AdminLogin from '../../components/admin/AdminLogin';
import AddUserForm from '../../components/admin/AddUserForm';
import UpdateUserForm from '../../components/admin/UpdateUserForm';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';

import styles from './Admin.module.css';
import DemptosLogoWhite from '../../assets/images/logo_demptos_blanc.png';

import { URL, deleteUser } from '../../api/Users';

const Admin = () => {
    const [login, setLogin] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    const [users, setUsers] = useState([]);
    const [userInfos, setUserInfos] = useState(null);

    const [filterOption, setFilterOption] = useState('name');
    const [search, setSearch] = useState('');

    const filterUsers = () => {
        var newTab = [];
        if (filterOption === 'client') {
            for (var i = 0; i < users.length; i++) {
                if (users[i].client.toLowerCase().match(search.toLowerCase())) {
                    newTab.push(users[i]);
                }
            }
        } else {
            for (var i = 0; i < users.length; i++) {
                if (users[i].lastname.toLowerCase().match(search.toLowerCase())) {
                    newTab.push(users[i]);
                }
            }
        }
        setUsers(newTab);
    }

    const suppressUser = async (email) => {
        await deleteUser({email: email});
        setIsFetching(true);
    }

    useEffect(() => {
        fetch(URL + '/get-users', {
            method: 'GET',
        })
            .then(res => res.json())
            .then(response => {
                setUsers(response.sort((a, b) => (a.lastname > b.lastname) ? 1 : -1));
                setIsFetching(false);
            })
            .catch(error => console.log(error));
        
        setUserInfos(null);
    }, [isFetching]);

    if (!login) {
        return (
            <AdminLogin setLogin={setLogin} />
        )
    }

    return (
        <div id={styles.adminContainer}>
            <img
                id={styles.demptosLogo}
                src={DemptosLogoWhite}
                alt="Demptos"
            />

            <Button
                style={{position: 'absolute', top: '5%', right: '5%'}}
                type="button"
                value="LOGOUT"
                onClick={() => setLogin(false)}
            />
            
            <div id={styles.dataManagerContainer}>
                <AddUserForm setIsFetching={setIsFetching} />
                <UpdateUserForm
                    userInfos={userInfos}
                    setIsFetching={setIsFetching}
                />
            </div>

            <p className={styles.formTitle}>USERS ACCESS LIST</p>
            <div id={styles.usersListContainer}>
                <div id={styles.usersList}>
                    <div id={styles.filterOptionsContainer}>
                        <button
                            className={styles.filterButton}
                            onClick={() => setIsFetching(true)}
                        >
                            <p className={styles.filterButtonText}>See all</p>
                        </button>
                        <button
                            className={styles.filterButton}
                            style={filterOption === 'name' ? {backgroundColor: '#6A5542'} : {}}
                            onClick={() => setFilterOption('name')}
                        >
                            <p
                                className={styles.filterButtonText}
                                style={filterOption === 'name' ? {color: 'white'} : {}}
                            >
                                By user name
                            </p>
                        </button>
                        <button
                            className={styles.filterButton}
                            style={filterOption === 'client' ? {backgroundColor: '#6A5542'} : {}}
                            onClick={() => setFilterOption('client')}
                        >
                            <p
                                className={styles.filterButtonText}
                                style={filterOption === 'client' ? {color: 'white'} : {}}
                            >
                                By client
                            </p>
                        </button>
                    </div>
                    <div id={styles.filterInputContainer}>
                        <TextInput
                            style={{
                                width: '20em',
                                marginTop: 0,
                                marginBottom: 0,
                                marginRight: '8px',
                                borderRadius: '1em',
                            }}
                            name="search"
                            placeholder="Search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        <button
                            className={styles.filterButton}
                            onClick={() => filterUsers()}
                        >
                            <p className={styles.filterButtonText}>Search</p>
                        </button>
                    </div>
                    {users !== undefined && users.map((user, key) =>
                        <div className={styles.userContainer} key={key}>
                            <p className={styles.userInfo}>{'Name : ' + user.lastname + ' ' + user.firstname + ' - Client : ' + user.client}</p>
                            <div id={styles.buttonsContainer}>
                                <button
                                    className={styles.button}
                                    onClick={() => setUserInfos(user)}
                                >
                                    <p className={styles.buttonText}>DETAILS</p>
                                </button>
                                <button
                                    className={styles.button}
                                    onClick={() => suppressUser(user.email)}
                                >
                                    <p className={styles.buttonText}>DELETE</p>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div id={styles.userSummary}>
                    <p className={styles.summaryInfoTitle}>User name : <label className={styles.summaryInfo}>{userInfos ? userInfos.lastname + ' ' + userInfos.firstname : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Client name : <label className={styles.summaryInfo}>{userInfos ? userInfos.client : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Contact : <label className={styles.summaryInfo}>{userInfos ? userInfos.email + ' - ' + userInfos.phone : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Access code : <label className={styles.summaryInfo}>{userInfos ? userInfos.password : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Responsable : <label className={styles.summaryInfo}>{userInfos ? userInfos.responsableName + ' - ' + userInfos.responsableEmail : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Last connexion date : <label className={styles.summaryInfo}>{userInfos ? userInfos.lastConnexion : ''}</label></p>
                    <p className={styles.summaryInfoTitle}>Number of connexions : <label className={styles.summaryInfo}>{userInfos ? userInfos.connexionNumber : ''}</label></p>
                </div>
            </div>
        </div>
    )
};

export default Admin;
