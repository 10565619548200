import React from 'react';
import styles from './Button.module.css';

import Zoom from 'react-reveal/Zoom';

const Button = (props) => {
    const {style, type, value, onClick} = props;

    return (
        <Zoom>
            <div
                id={styles.container}
                style={style}
            >
                <input
                    id={styles.button}
                    type={type}
                    value={value}
                    onClick={onClick}
                />
                <div id={styles.underline}></div>
            </div>
        </Zoom>
    )
}

export default Button;
