import ImgStep1 from '../assets/images/img_step_1.jpg';
import ImgStep2 from '../assets/images/img_step_2.jpg';
import ImgStep3 from '../assets/images/img_step_3.jpg';
import ImgStep4 from '../assets/images/img_step_4.jpg';
import ImgStep5 from '../assets/images/img_step_5.jpg';
import ImgStep6 from '../assets/images/img_step_6.jpg';
import ImgStep7 from '../assets/images/img_step_7.jpg';

export const displayImg = (step) => {
    switch (step) {
        case 1:
            return ImgStep1;
            break;
        case 2:
            return ImgStep2;
            break;
        case 3:
            return ImgStep3;
            break;
        case 4:
            return ImgStep4;
            break;
        case 5:
            return ImgStep5;
            break;
        case 6:
            return ImgStep6;
            break;
        case 7:
            return ImgStep7;
            break;
        default:
            return ImgStep1;
    }
};
