import React from 'react';
import MyBarrelLogoWhite from '../../assets/images/logo_design_my_barrel_blanc.png';

const MyBarrelLogo = (props) => {
    const { color, isPortrait } = props;

    if (color === 'white') {
        return (
            <img
                style={{
                    position: 'absolute',
                    top: isPortrait ? window.innerHeight/2.085 : '3.5em',
                    height: '1.7em',
                    width: 'auto',
                }}
                src={MyBarrelLogoWhite}
                alt="Design my Barrel"
            />
        )
    };
};

export default MyBarrelLogo;
