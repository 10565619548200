import React from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const Finishing = (props) => {
    const { step, hoopsInfos, sanding, setSanding, hoops, setHoops, submit, isPortrait, backgroundColor } = props;

    const sandingInfos = [
        {value: 'NORMAL'},
        {value: 'PREMIUM'},
    ];

    return (
        <div id={styles.container}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title}>FINISHING</p>

            <Fade>
                <p id={styles.finishingSubtitle}>SANDING</p>
                {sandingInfos.map((sandingInfo, key) => (
                    <Fade key={key} delay={200 + key * 100}>
                        <ParamButton
                            style={sanding === sandingInfo.value ? {backgroundColor: 'white', color: '#B18E78'} : null}
                            backgroundColor={backgroundColor}
                            text={sandingInfo.value}
                            info={sandingInfo.info}
                            selected={sanding === sandingInfo.value}
                            onPress={() => setSanding(sandingInfo.value)}
                        />
                    </Fade>
                ))}

                <p id={styles.finishingSubtitle}>GALVANIZED HOOPS</p>
                {hoopsInfos.map((hoopsInfo, key) => (
                    <Fade key={key} delay={200 + key * 100}>
                        <ParamButton
                            style={hoops === hoopsInfo.value ? {backgroundColor: 'white', color: '#B18E78'} : null}
                            backgroundColor={backgroundColor}
                            text={hoopsInfo.value}
                            info={hoopsInfo.info}
                            selected={hoops === hoopsInfo.value}
                            onPress={() => setHoops(hoopsInfo.value)}
                        />
                    </Fade>
                ))}

                <Fade delay={600}>
                    <input
                        id={styles.finalButton}
                        style={isPortrait ? {bottom: '2em', right: '3em'} : {}}
                        type="button"
                        value="FINALIZE"
                        onClick={() => submit()}
                    />
                </Fade>
            </Fade>
        </div>
    );
};

export default Finishing;
