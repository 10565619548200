import React, { useState } from 'react';
import { authenticate } from '../../routes/AuthService';
import { useHistory, Redirect } from "react-router-dom";
import '../../App.css';
import styles from './Login.module.css';
import DemptosLogoWhite from '../../assets/images/logo_demptos_blanc.png';
import MyBarrelLogoWhite from '../../assets/images/logo_design_my_barrel_blanc.png';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import { URL } from '../../api/Users';

const Login = (props) => {
    let history = useHistory();

    const [password, setPassword] = useState('');

    const [showError, setShowError] = useState(false);

    const login = (e) => {
        e.preventDefault();
        setShowError(false);

        if (password.length === 0) {
            setShowError(true);
        } else {
            fetch(URL + '/login', {
                method: 'POST',
                body: JSON.stringify({
                    password: password,
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        authenticate(password);
                        history.push("/barrel-conceptor");
                    } else {
                        setShowError(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setShowError(true)
                });
        }
    }

    return (
        <div id={styles.container}>
            <img
                id={styles.demptosLogo}
                src={DemptosLogoWhite}
                alt="Demptos"
            />

            <img
                id={styles.myBarrelLogo}
                src={MyBarrelLogoWhite}
                alt="Design my Barrel"
            />

            <form id={styles.form} onSubmit={login}>
                <TextInput
                    name="code"
                    placeholder="Your code"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    password
                />

                <div id={styles.errorContainer}>
                    {showError && <p className={styles.text}>
                        Your code doesn't exist, please retry or <a className={styles.text} href="mailto:contact@demptos.fr" style={{fontSize: '1em', fontWeight: 'bold'}}>contact us.</a>
                    </p>}
                </div>

                <Button
                    type="submit"
                    value="LOGIN"
                />
            </form>

            <a style={{textDecoration: 'none', position: 'absolute', bottom: '5%'}} href="https://www.demptos.fr/">
                <Button
                    type="button"
                    value="RETURN TO WEBSITE"
                />
            </a>
        </div>
    )
};

export default Login;
