import React, { useState, useEffect } from 'react';

import TextInput from '../../ui/TextInput';

import styles from './style.module.css';

import { updateUser } from '../../../api/Users';

const UpdateUserForm = ({ userInfos, setIsFetching }) => {
  const [actualEmail, setActualEmail] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [client, setClient] = useState('');
  const [password, setPassword] = useState('');
  const [responsableName, setResponsableName] = useState('');
  const [responsableEmail, setResponsableEmail] = useState('');

  const submit = async () => {
      if (!userInfos) {
          alert('Please select a user');
      } else if (firstname.length === 0) {
          alert('Please enter a firstname');
      } else if (lastname.length === 0) {
          alert('Please enter a lastname');
      } else if (email.length === 0) {
          alert('Please enter an email');
      } else if (client.length === 0) {
          alert('Please enter a client name');
      } else if (password.length === 0) {
          alert('Please enter an access code');
      } else if (responsableEmail.length === 0) {
          alert('Please enter a responsable email');
      } else {
          await updateUser({
              actualEmail: actualEmail,
              firstname: firstname,
              lastname: lastname,
              email: email,
              phone: phone,
              client: client,
              password: password,
              responsableName: responsableName,
              responsableEmail: responsableEmail,
          });
          setIsFetching(true);
      }
  }

  useEffect(() => {
    setActualEmail(userInfos?.email || '');
    setFirstname(userInfos?.firstname || '');
    setLastname(userInfos?.lastname || '');
    setEmail(userInfos?.email || '');
    setPhone(userInfos?.phone || '');
    setClient(userInfos?.client || '');
    setPassword(userInfos?.password || '');
    setResponsableName(userInfos?.responsableName || '');
    setResponsableEmail(userInfos?.responsableEmail || '');
  }, [userInfos]);

  return (
    <div id={styles.container}>
        <p className={styles.formTitle}>UPDATE USER DATA</p>
        <form id={styles.addUserForm}>
            <div className={styles.inputsLine}>
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="firstname"
                    placeholder="Firstname"
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    required
                />
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="lastname"
                    placeholder="Lastname"
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    required
                />
            </div>
            <div className={styles.inputsLine}>
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                />
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                />
            </div>
            <TextInput
                style={{width: '90%', marginTop: '1%', marginBottom: '1%'}}
                name="client"
                placeholder="Client"
                value={client}
                onChange={(event) => setClient(event.target.value)}
                required
            />
            <TextInput
                style={{width: '90%', marginTop: '1%', marginBottom: '1%'}}
                name="accessCode"
                placeholder="Access Code"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
            />
            <div className={styles.inputsLine}>
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="responsableName"
                    placeholder="Responsable Name"
                    value={responsableName}
                    onChange={(event) => setResponsableName(event.target.value)}
                />
                <TextInput
                    style={{width: '40%', marginTop: '1%', marginBottom: '1%'}}
                    name="responsableEmail"
                    placeholder="Responsable Email"
                    value={responsableEmail}
                    onChange={(event) => setResponsableEmail(event.target.value)}
                    required
                />
            </div>
            <input
                id={styles.submitButton}
                type="button"
                value="UPDATE USER"
                onClick={submit}
            />
        </form>
    </div>
  )
};

export default UpdateUserForm;