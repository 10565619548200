import React, { useState } from 'react';
import { URL } from '../../api/Users';
import styles from './Summary.module.css';
import Fade from 'react-reveal/Fade';
import DemptosLogoPNG from '../../assets/images/logo_demptos_blanc.png';

import DemptosLogo from '../ui/DemptosLogo';
import MyBarrelLogo from '../ui/MyBarrelLogo';
import TextInput from '../ui/TextInput';
import Alert from '../ui/Alert';
import Button from '../ui/Button';

import { transformLowerCase } from '../../utils/transformLowerCase';
import { getPassword } from '../../routes/AuthService';

const Summary = (props) => {
    const {
        shape,
        woodOrigine,
        woodGrain,
        seasoning,
        toasting,
        oakToasting,
        toastAndChar,
        headToasting,
        sanding,
        hoops,
        modifyBarrel,
        isPortrait,
    } = props;

    const [message, setMessage] = useState('');

    const [showSentMessage, setShowSentMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const sendMyBarrel = () => {
        fetch(URL + '/barrel-email', {
            method: 'POST',
            body: JSON.stringify({
                password: getPassword(),
                shape: shape,
                woodOrigin: woodOrigine,
                woodGrain: woodGrain ? woodGrain : null,
                seasoning: seasoning,
                toasting: toasting,
                traditionnalToasting: oakToasting ? oakToasting : null,
                toastAndChar: toastAndChar ? toastAndChar : null,
                headToasting: headToasting,
                sanding: sanding,
                hoops: hoops,
                message: message,
            })
        })
            .then(response => {
                if (response.status < 300) {
                    setShowSentMessage(true);
                } else {
                    setShowErrorMessage(true);
                }
            })
            .catch(error => setShowErrorMessage(true));
    }

    if (isPortrait) {
        return (
            <div
                id={styles.container}
                style={{flexDirection: 'column', alignItems: 'center', height: 'auto', paddingTop: '8em'}}
            >
                <MyBarrelLogo color='white'/>

                <Fade bottom>
                    <label style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p className={styles.title}>YOUR BARREL</p>
                        <p className={styles.portraitStepTitle}>SHAPE / <label className={styles.portraitStepValue}>{shape}</label></p>
                        <p className={styles.portraitStepTitle}>WOOD ORIGIN / <label className={styles.portraitStepValue}>{transformLowerCase(woodOrigine)}</label></p>
                        {woodGrain && <p className={styles.portraitStepTitle}>WOOD GRAIN / <label className={styles.portraitStepValue}>{transformLowerCase(woodGrain)}</label></p>}
                        <p className={styles.portraitStepTitle}>SEASONING / <label className={styles.portraitStepValue}>{transformLowerCase(seasoning)}</label></p>
                        <p className={styles.portraitStepTitle}>TOASTING / <label className={styles.portraitStepValue}>{transformLowerCase(toasting)} / {oakToasting ? transformLowerCase(oakToasting) : toastAndChar}</label></p>
                        <p className={styles.portraitStepTitle}>HEAD TOASTING / <label className={styles.portraitStepValue}>{transformLowerCase(headToasting)}</label></p>
                        <p className={styles.portraitStepTitle}>FINISHING / <label className={styles.portraitStepValue}>{transformLowerCase(sanding)} sanding / {hoops} hoops</label></p>

                        <Button
                            style={{marginTop: '2em', marginBottom: '1em'}}
                            type="button"
                            value="MODIFY MY BARREL"
                            onClick={() => modifyBarrel()}
                        />
                    </label>
                </Fade>

                <Fade bottom>
                    <label style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p id={styles.portraitText}>
                            For any additionnal comments or specifications, please leave a message below.
                        </p>
                        <form id={styles.form}>
                            <textarea
                                id={styles.textArea}
                                style={{width: '90%'}}
                                name='specifications'
                                placeholder='Your message'
                                value={message}
                                onChange={event => setMessage(event.target.value)}
                                cols={70}
                                rows={10}
                                required
                            >
                            </textarea>

                            <input
                                id={styles.confirmButton}
                                style={{marginBottom: '5em'}}
                                type="button"
                                value="CONFIRM"
                                onClick={() => sendMyBarrel()}
                            />
                        </form>
                    </label>
                </Fade>

                <Fade bottom>
                    <label style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <img
                            style={{height: '5em', width: 'auto'}}
                            src={DemptosLogoPNG}
                            alt='Demptos logo'
                        />

                        <p className={styles.title} style={{marginTop: '3em'}}>ANY QUESTIONS ?</p>
                        <p className={styles.portraitStepTitle}>PHONE</p>
                        <p className={styles.portraitStepValue} style={{marginBottom: '2em'}}>+33 (0)5 57 97 12 50</p>
                        <p className={styles.portraitStepTitle}>EMAIL</p>
                        <p className={styles.portraitStepValue} style={{marginBottom: '2em'}}>contact@demptos.fr</p>
                        <p className={styles.portraitStepTitle}>ADDRESS</p>
                        <p className={styles.portraitStepValue} style={{marginBottom: '2em'}}>Tonnellerie Demptos SAS<br/>18 avenue de Luzanne, BP 10<br/>33880 Saint-Caprais-de-Bordeaux</p>

                        <a style={{textDecoration: 'none', marginTop: '2em', marginBottom: '4em'}} href="https://www.demptos.fr/">
                            <Button
                                type="button"
                                value="RETURN TO WEBSITE"
                            />
                        </a>
                    </label>
                </Fade>

                {showSentMessage && <Alert
                    style={{width: '70%'}}
                    text={'Thank you ! Your barrel is saved. We will contact you shortly.'}
                    onClick={() => setShowSentMessage(false)}
                />}

                {showErrorMessage && <Alert
                    style={{width: '70%'}}
                    text={'Sorry, an error occurred. Please try again later.'}
                    onClick={() => setShowErrorMessage(false)}
                />}
            </div>
        );
    }

    return (
        <div id={styles.container}>
            <Fade bottom delay={400}>
                <div id={styles.sideContainer}>
                    <div>
                        <p className={styles.title}>YOUR<br/>BARREL</p>
                        <p className={styles.stepTitle}>SHAPE</p>
                        <p className={styles.stepValue}>{shape}</p>
                        <p className={styles.stepTitle}>WOOD ORIGIN</p>
                        <p className={styles.stepValue}>{transformLowerCase(woodOrigine)}</p>
                        {woodGrain && <p className={styles.stepTitle}>WOOD GRAIN</p>}
                        {woodGrain && <p className={styles.stepValue}>{transformLowerCase(woodGrain)}</p>}
                        <p className={styles.stepTitle}>SEASONING</p>
                        <p className={styles.stepValue}>{transformLowerCase(seasoning)}</p>
                        <p className={styles.stepTitle}>TOASTING</p>
                        <p className={styles.stepValue}>{toasting === 'OAK TOASTING' ? 'Oak toasting' : 'ToastandChar®'}<br/>{oakToasting ? transformLowerCase(oakToasting) : toastAndChar}</p>
                        <p className={styles.stepTitle}>HEAD TOASTING</p>
                        <p className={styles.stepValue}>{transformLowerCase(headToasting)}</p>
                        <p className={styles.stepTitle}>FINISHING</p>
                        <p className={styles.stepValue}>{transformLowerCase(sanding)} sanding<br/>{hoops} hoops</p>
                    </div>
                    <Button
                        type="button"
                        value="MODIFY MY BARREL"
                        onClick={() => modifyBarrel()}
                    />
                </div>
            </Fade>

            <Fade bottom>
                <div id={styles.centerContainer}>
                    <p id={styles.text}>
                        For any additionnal comments or specifications, please leave a message below.
                    </p>
                    <form id={styles.form}>
                        <textarea
                            id={styles.textArea}
                            name='specifications'
                            placeholder='Your message'
                            value={message}
                            onChange={event => setMessage(event.target.value)}
                            cols={60}
                            rows={14}
                            required
                        >
                        </textarea>

                        <input
                            id={styles.confirmButton}
                            type="button"
                            value="CONFIRM"
                            onClick={() => sendMyBarrel()}
                        />
                    </form>
                </div>
            </Fade>

            <Fade bottom delay={400}>
                <div id={styles.sideContainer}>
                    <div>
                        <p className={styles.title}>ANY<br/>QUESTIONS ?</p>
                        <p className={styles.stepTitle}>PHONE</p>
                        <p className={styles.stepValue}>+33 (0)5 57 97 12 50</p>
                        <p className={styles.stepTitle}>EMAIL</p>
                        <p className={styles.stepValue}>contact@demptos.fr</p>
                        <p className={styles.stepTitle}>ADDRESS</p>
                        <p className={styles.stepValue} style={{lineHeight: '15px'}}>Tonnellerie Demptos SAS<br/>18 avenue de Luzanne, BP 10<br/>33880 Saint-Caprais-de-Bordeaux</p>
                    </div>
                    <a style={{textDecoration: 'none'}} href="https://www.demptos.fr/">
                        <Button
                            type="button"
                            value="RETURN TO WEBSITE"
                        />
                    </a>
                </div>
            </Fade>

            <MyBarrelLogo color='white'/>
            <DemptosLogo color='white'/>

            {showSentMessage && <Alert
                style={{height: '45%', width: '40%', marginTop: '12%'}}
                text={'Thank you ! Your barrel is saved. We will contact you shortly.'}
                onClick={() => setShowSentMessage(false)}
            />}

            {showErrorMessage && <Alert
                style={{height: '45%', width: '40%', marginTop: '12%'}}
                text={'Sorry, an error occurred. Please try again later.'}
                onClick={() => setShowErrorMessage(false)}
            />}
        </div>
    );
};

export default Summary;
