import React from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const Shape = (props) => {
    const { step, param, setParam, isPortrait, backgroundColor } = props;

    const infos = [
        {value: '200 L', info: 'BOURBON SHAPE'},
        {value: '225 L / 90 cm'},
        {value: '225 L / 95 cm', info: 'BORDEAUX SHAPE'},
        {value: '250 L / 90 cm', info: 'BOURBON HOGHSHEAD SHAPE'},
        {value: '300 L', info: 'HOGHSHEAD'},
        {value: '350 L', info: 'COGNAC SHAPE'},
        {value: '400 L', info: 'ARMAGNAC SHAPE'},
        {value: '500 L', info: 'PUNCHEON'},
    ];

    return (
        <div id={styles.container}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title}>SHAPE</p>
            <p
                id={styles.subtitle}
                style={isPortrait ? {width: '80%'} : null}
            >
                The smaller the cask, the greater oxygenation and a better oxygen diffusion and
                higher rate extraction of wood components.
            </p>

            {infos.map((info, key) => (
                <Fade key={key} delay={300 + key * 100}>
                    <ParamButton
                        style={param === info.value ? {backgroundColor: 'white', color: '#858385'} : null}
                        backgroundColor={backgroundColor}
                        text={info.value}
                        info={info.info}
                        selected={param === info.value}
                        onPress={() => setParam('shape', info.value)}
                    />
                </Fade>
            ))}
        </div>
    );
};

export default Shape;
