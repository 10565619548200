import React, { useState } from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const Toasting = (props) => {
    const {
        step,
        param,
        setParam,
        oakToasting,
        toastAndChar,
        isPortrait,
        backgroundColor,
    } = props;

    const infos = [
        {value: 'OAK TOASTING'},
        {value: 'TOAST&CHAR®'},
    ];

    const oakToastingInfo = 'Long brasero oak toasting.';

    const oakToastingParams = [
        {value: 'LIGHT', info: 'Fresh and spicy aromas. Coconut, cinnamon and clove notes.'},
        {value: 'MEDIUM', info: 'Complex and harmonious aromatic profile. Addition of toasted, tobacco and nutty derived notes to fresh and spicy aromas.'},
        {value: 'MEDIUM +', info: 'Large palette of aromas, reminiscent of roasted flavours, cocoa beans, pecan nuts. Balancing the spicy and slightly-toasted nuances.'},
        {value: 'HEAVY', info: 'Empyreumatic and mineral aromas. Dark chocolate, coffee and smoke flavours.'},
    ];

    const toastAndCharInfo = 'An aromatic toasting combined with a charring. Charring acts almost as a filter, helping to remove unpleasant aroma compounds, and allowing greater penetration of the spirit deep inside the wood stave. This leads to a higher release and extraction of aroma compounds. A process which makes it possible to obtain a double impact on the wood : on surface and at depth. This results to a larger aromatic bouquet.';

    const toastAndCharParams = [
        {value: '# 1', info: 'Short Toast&Char (see reference handbook)'},
        {value: '# 2', info: 'Long Toast&Char (see reference handbook)'},
    ];

    const [showOakToastingInfo, setShowOakToastingInfo] = useState(false);
    const [showToastAndCharInfo, setShowToastAndCharInfo] = useState(false);

    return (
        <div id={styles.container} style={{height: '65%', justifyContent: 'flex-start'}}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title} style={{marginBottom: '2em'}}>TOASTING</p>

            <div style={isPortrait ? {
                width: '100%',
            } : {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
            }}>
                {infos.map((info, key) => (
                    <div key={key}>
                        <Fade delay={300 + key * 100}>
                            <ParamButton
                                style={param === info.value ? {marginLeft: '3em', marginRight: '3em', backgroundColor: 'white', color: '#A27F77'} : {marginLeft: '3em', marginRight: '3em'}}
                                otherIsSelected={param === 'OAK TOASTING'}
                                backgroundColor={backgroundColor}
                                step={'TOASTING'}
                                text={info.value}
                                info={info.info}
                                selected={param === info.value}
                                onMouse={info.value === 'TOAST&CHAR®' ? () => setShowToastAndCharInfo(true) : () => setShowOakToastingInfo(true)}
                                offMouse={info.value === 'TOAST&CHAR®' ? () => setShowToastAndCharInfo(false) : () => setShowOakToastingInfo(false)}
                                onPress={() => setParam('toasting', info.value)}
                            />
                        </Fade>

                        <div id={styles.subParamsContainer}>
                            {!isPortrait && param === info.value && param === 'OAK TOASTING' && <p id={styles.subtitle} style={{position: 'absolute', width: '20em', marginTop: '0em'}}>{oakToastingInfo}</p> ||
                            !isPortrait && info.value === 'OAK TOASTING' && showOakToastingInfo && <p id={styles.subtitle} style={{position: 'absolute', width: '20em', marginTop: '0em'}}>{oakToastingInfo}</p>}

                            {isPortrait && info.value === 'OAK TOASTING' && <p id={styles.subtitle} style={{width: '20em', marginBottom: '2em'}}>{oakToastingInfo}</p>}

                            {param === info.value && param === 'OAK TOASTING' && <div id={styles.subParamsContainer} style={isPortrait ? {marginTop: '1em'} : {marginTop: '3em'}}>
                                {oakToastingParams.map((oakToastingParam, key) => (
                                    <Fade delay={key * 100}>
                                        <ParamButton
                                            key={key}
                                            style={oakToasting === oakToastingParam.value ? {backgroundColor: 'white', color: '#A27F77'} : null}
                                            backgroundColor={backgroundColor}
                                            text={oakToastingParam.value}
                                            info={oakToastingParam.info}
                                            selected={oakToasting === oakToastingParam.value}
                                            onPress={() => setParam('oakToasting', oakToastingParam.value)}
                                        />
                                    </Fade>
                                ))}
                            </div>}
                        </div>

                        <div id={styles.subParamsContainer}>
                            {!isPortrait && param === info.value && param === 'TOAST&CHAR®' && <p id={styles.subtitle} style={{position: 'absolute', width: '20em', marginTop: '0em'}}>{toastAndCharInfo}</p> ||
                            !isPortrait && info.value === 'TOAST&CHAR®' && showToastAndCharInfo && <p id={styles.subtitle} style={{position: 'absolute', width: '20em', marginTop: '0em'}}>{toastAndCharInfo}</p>}

                            {isPortrait && info.value === 'TOAST&CHAR®' && <p id={styles.subtitle} style={{width: '20em', marginBottom: '0em'}}>{toastAndCharInfo}</p>}

                            {param === info.value && param === 'TOAST&CHAR®' && <div style={isPortrait ? {marginTop: '1em'} : {marginTop: '12em'}}>
                                {toastAndCharParams.map((toastAndCharParam, key) => (
                                    <Fade delay={key * 100}>
                                        <ParamButton
                                            key={key}
                                            style={toastAndChar === toastAndCharParam.value ? {marginTop: '1em', marginBottom: '1em', backgroundColor: 'white', color: '#A27F77'} : {marginTop: '1em', marginBottom: '1em'}}
                                            backgroundColor={backgroundColor}
                                            step={'TOASTANDCHAR'}
                                            text={toastAndCharParam.value}
                                            info={toastAndCharParam.info}
                                            selected={toastAndChar === toastAndCharParam.value}
                                            onPress={() => setParam('toastAndChar', toastAndCharParam.value)}
                                        />
                                    </Fade>
                                ))}
                            </div>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Toasting;
