import React from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const Seasoning = (props) => {
    const { step, param, setParam, isPortrait, backgroundColor } = props;

    const infos = [
        {value: 'OVER 24 MONTHS', info: 'The major wood transformations occur at this stage. Decrease of astringency and bitterness sensations. Significant effect on the aromatic profile of wood.'},
        {value: 'OVER 36 MONTHS', info : 'Increase of coconut notes due to high level of whisky lactones (isomer cis). Lower content of ellagitannins.'},
    ];

    return (
        <div id={styles.container}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title}>SEASONING</p>
            <p
                id={styles.subtitle}
                style={isPortrait ? {width: '80%'} : {width: '55%'}}
            >
                Natural seasoning improves some of the oak wood's qualities.<br/>
                Lowers the moisture content of oak, and reduces bitterness and astringency. Increases some
                aromatic properties.
            </p>

            {infos.map((info, key) => (
                <Fade key={key} delay={300 + key * 100}>
                    <ParamButton
                        style={param === info.value ? {backgroundColor: 'white', color: '#9EA29D'} : null}
                        backgroundColor={backgroundColor}
                        text={info.value}
                        info={info.info}
                        selected={param === info.value}
                        onPress={() => setParam('seasoning', info.value)}
                    />
                </Fade>
            ))}
        </div>
    );
};

export default Seasoning;
