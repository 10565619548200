import React, { useState } from 'react';
import { URL } from '../../api/Users';

import styles from './style.module.css';
import CloseIcon from '../../assets/icons/close_white_icon.png';

import Alert from '../../components/ui/Alert';

import { getPassword } from '../../routes/AuthService';

const DesignWithOurTeam = (props) => {
    const { showDesignWithOurTeam, setMailSent, isPortrait } = props;

    const [message, setMessage] = useState('');
    const [emptyAlert, showEmptyAlert] = useState(false);
    const [alert, showAlert] = useState(false);

    const sendEmail = () => {
        if (message.length === 0) {
            return showEmptyAlert(true);
        }

        fetch(URL + '/help-email', {
            method: 'POST',
            body: JSON.stringify({
                password: getPassword(),
                message: message,
            })
        })
            .then(response => {
                if (response.status > 299) {
                    showAlert(true);
                } else {
                    setMailSent(true);
                    showDesignWithOurTeam(false);
                }
            })
            .catch(error => showAlert(true));
    }

    return (
        <div id={styles.dwotContainer}>
            <img
                id={styles.dwotCloseIcon}
                style={isPortrait ? {right: '5%'} : {}}
                src={CloseIcon}
                alt={'Close'}
                onClick={() => showDesignWithOurTeam(false)}
            />

            <h2 id={styles.dwotTitle}>DESIGN YOUR BARREL WITH OUR TEAM</h2>

            <p id={styles.dwotSubtitle}>
                For any additionnal comments or specifications, please leave a message below.
            </p>

            <form id={styles.dwotForm}>

                <textarea
                    id={styles.dwotTextArea}
                    name='specifications'
                    placeholder='Your message'
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    cols={isPortrait ? 40 : 70}
                    rows={isPortrait ? 10 : 15}
                    required
                >
                </textarea>

                <input
                    id={styles.dwotButton}
                    type="button"
                    value="CONTACT OUR TEAM"
                    onClick={() => sendEmail()}
                />

            </form>

            {emptyAlert && <Alert
                text={'Please enter a message before submit.'}
                onClick={() => showEmptyAlert(false)}
            />}
            {alert && <Alert
                text={'Sorry, an error occurred. Please try again later.'}
                onClick={() => showAlert(false)}
            />}
        </div>
    )
}

export default DesignWithOurTeam;
