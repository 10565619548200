import React, { useState } from 'react';

import styles from './style.module.css';
import Fade from 'react-reveal/Fade';
import BackgroundImg from '../../assets/images/img_welcome.jpg';
import DemptosLogoWhite from '../../assets/images/logo_demptos_blanc.png';
import MyBarrelLogoWhite from '../../assets/images/logo_design_my_barrel_blanc.png';

import Button from '../../components/ui/Button';

import DesignWithOurTeam from './DesignWithOurTeam';

const Welcome = (props) => {
    const { isPortrait, setWelcome, disconnect } = props;

    const [designWithOurTeam, showDesignWithOurTeam] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    if (designWithOurTeam) {
        return (
            <DesignWithOurTeam
                showDesignWithOurTeam={showDesignWithOurTeam}
                setMailSent={setMailSent}
                isPortrait={isPortrait}
            />
        )
    }

    return (
        <div id={styles.welcomeContainer}>
            <img
                id={styles.backgroundImg}
                style={isPortrait ? {height: '97%', width: '96%'} : null}
                src={BackgroundImg}
                alt="Design my Barrel"
            />
            <div
                id={styles.opacityFilter}
                style={isPortrait ? {height: '97%', width: '96%'} : null}
            >
            </div>

            <div
                id={styles.infosContainer}
                style={isPortrait ? {right: 'inherit', width: '100%'} : null}
            >
                <Fade>
                    <img
                        style={{
                            height: '11%',
                            width: 'auto',
                            marginBottom: isPortrait ? '2em' : '4em',
                        }}
                        src={DemptosLogoWhite}
                        alt="Demptos"
                    />
                </Fade>

                <Fade delay={150}>
                    <img
                        style={{
                            height: '4%',
                            width: 'auto',
                            marginTop: isPortrait ? '1em' : '2em',
                            marginBottom: '2em',
                        }}
                        src={MyBarrelLogoWhite}
                        alt="Design my Barrel"
                    />
                </Fade>

                <Fade delay={200}>
                    <p
                        id={styles.text}
                        style={isPortrait ? {width: '80%'} : null}
                    >
                        Welcome to the barrel configurator “ Design My barrel ”<br/>
                        Make your custom barrel according to the expected results for your final product !<br/><br/>
                        You can also ask for the Demptos Research Center assistance to design your barrel step by step.
                        Click on the link below.
                    </p>
                </Fade>

                <Fade bottom delay={250}>
                    <input
                        id={styles.startButton1}
                        type="button"
                        value="START NOW"
                        onClick={() => setWelcome(false)}
                    />
                </Fade>

                <Fade bottom delay={350}>
                    <input
                        id={mailSent ? styles.startButton2Off : styles.startButton2}
                        type="button"
                        value="DESIGN YOUR BARREL WITH OUR TEAM"
                        onClick={() => showDesignWithOurTeam(true)}
                        disabled={mailSent}
                    />
                </Fade>

                <Fade bottom delay={400}>
                {mailSent && <p id={styles.emailSentMessage}>
                    You asked an assistance to our team for the creation of your barrel, we will contact you shortly.
                </p>}
                </Fade>

                <Button
                    style={{marginTop: '6%'}}
                    type="button"
                    value="LOGOUT"
                    onClick={disconnect}
                />
            </div>
        </div>
    )
}

export default Welcome;
