import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import styles from './style.module.css';
import Fade from 'react-reveal/Fade';
import BackgroundImage from '../../components/ui/BackgroundImage';
import DemptosLogoWhite from '../../assets/images/logo_demptos_blanc.png';
import MyBarrelLogoWhite from '../../assets/images/logo_design_my_barrel_blanc.png';
import { getBackgroundColor } from '../../utils/backgroundColor';

import Welcome from './Welcome';
import Button from '../../components/ui/Button';
import MyBarrelLogo from '../../components/ui/MyBarrelLogo';
import DemptosLogo from '../../components/ui/DemptosLogo';
import Shape from '../../components/steps/Shape';
import WoodOrigine from '../../components/steps/WoodOrigine';
import WoodGrain from '../../components/steps/WoodGrain';
import Seasoning from '../../components/steps/Seasoning';
import Toasting from '../../components/steps/Toasting';
import HeadToasting from '../../components/steps/HeadToasting';
import Finishing from '../../components/steps/Finishing';
import ChangeStepButton from '../../components/ui/ChangeStepButton';
import Alert from '../../components/ui/Alert';
import Summary from '../../components/steps/Summary';

import { stepName } from '../../utils/stepName';
import { logout } from '../../routes/AuthService';

const BarrelConceptor = () => {
    let history = useHistory();

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const [welcome, setWelcome] = useState(true);

    const [step, setStep] = useState(1);

    const [shape, setShape] = useState(undefined);
    const [woodOrigine, setWoodOrigine] = useState(undefined);
    const [woodGrain, setWoodGrain] = useState(undefined);
    const [seasoning, setSeasoning] = useState(undefined);
    const [toasting, setToasting] = useState(undefined);
    const [oakToasting, setOakToasting] = useState(undefined);
    const [toastAndChar, setToastAndChar] = useState(undefined);
    const [headToasting, setHeadToasting] = useState(undefined);
    const [sanding, setSanding] = useState(undefined);
    const [hoops, setHoops] = useState(undefined);

    const [showError, setShowError] = useState(false);

    const [showSummary, setShowSummary] = useState(false);

    const disconnect = () => {
        logout();
        history.goBack();
    }

    const validParam = (name, value) => {
        switch (name) {
            case 'shape':
                setShape(value);
                changeStep(step + 1);
                break;
            case 'woodOrigine':
                setWoodOrigine(value);
                changeStep(shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? value === 'FRENCH OAK' ? step + 1 : step + 2 : step + 2);
                break;
            case 'woodGrain':
                setWoodGrain(value);
                changeStep(step + 1);
                break;
            case 'seasoning':
                setSeasoning(value);
                changeStep(step + 1);
                break;
            case 'toasting':
                setToasting(value);
                break;
            case 'oakToasting':
                setToastAndChar(undefined);
                setOakToasting(value);
                changeStep(step + 1);
                break;
            case 'toastAndChar':
                setOakToasting(undefined);
                setToastAndChar(value);
                changeStep(step + 1);
                break;
            case 'headToasting':
                setHeadToasting(value);
                changeStep(step + 1);
                break;
            default:

        }
    }

    const displayError = (bool) => {
        window.scroll({
            top: window.innerHeight/4,
            behavior: 'smooth',
        });
        setShowError(bool);
    }

    const changeStep = (step) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        setStep(step);
    }

    const nextStep = () => {
        switch (step) {
            case 1:
                if (!shape) {
                    displayError(true);
                } else {
                    changeStep(step + 1);
                }
                break;
            case 2:
                if (!woodOrigine) {
                    displayError(true);
                } else {
                    shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? changeStep(step + 1) : changeStep(step + 2) : changeStep(step + 2);
                }
                break;
            case 3:
                if (!woodGrain) {
                    displayError(true);
                } else {
                    changeStep(step + 1);
                }
                break;
            case 4:
                if (!seasoning) {
                    displayError(true);
                } else {
                    changeStep(step + 1);
                }
                break;
            case 5:
                if (!oakToasting && !toastAndChar) {
                    displayError(true);
                } else {
                    changeStep(step + 1);
                }
                break;
            case 6:
                if (!headToasting) {
                    displayError(true);
                } else {
                    changeStep(step + 1);
                }
                break;
            default:
                console.log('Error');
        }
    }

    const modifyBarrel = () => {
        setShowSummary(false);
        changeStep(1);
    }

    const submit = () => {
        if (!sanding || !hoops) {
            displayError(true);
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            setShowSummary(true);
        }
    }

    //////////////////////RENDER///////////////////////////

    if (welcome) {
        return (
            <Welcome
                isPortrait={isPortrait}
                setWelcome={setWelcome}
                disconnect={disconnect}
            />
        );
    }

    if (showSummary) {
        return (
            <Summary
                shape={shape}
                woodOrigine={woodOrigine}
                woodGrain={woodGrain}
                seasoning={seasoning}
                toasting={toasting}
                oakToasting={oakToasting}
                toastAndChar={toastAndChar}
                headToasting={headToasting}
                sanding={sanding}
                hoops={hoops}
                modifyBarrel={modifyBarrel}
                isPortrait={isPortrait}
            />
        )
    }

    return (
        <div
            id={styles.stepContainer}
            style={isPortrait ? {position: 'static', height: 'auto', flexDirection: 'column', alignItems: 'center'} : null}
        >
            <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {height: window.innerHeight/2} : {width: '50%'}}
                >
                    <BackgroundImage step={1}/>
                </div>
            </Fade>

            {step >= 2 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={2}/>
                </div>
            </Fade>}

            {step >= 3 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={3}/>
                </div>
            </Fade>}

            {step >= 4 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={4}/>
                </div>
            </Fade>}

            {step >= 5 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={5}/>
                </div>
            </Fade>}

            {step >= 6 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={6}/>
                </div>
            </Fade>}

            {step >= 7 && <Fade top>
                <div
                    id={styles.imgContainer}
                    style={isPortrait ? {position: 'absolute', top: 0, height: window.innerHeight/2} : {position: 'absolute', left: 0, height: '100%', width: '50%'}}
                >
                    <BackgroundImage step={7}/>
                </div>
            </Fade>}


            <div
                id={styles.paramsContainer}
                style={isPortrait ? {
                    width: '100%',
                    backgroundColor: getBackgroundColor(step),
                    paddingTop: '6em',
                    paddingBottom: '8em',
                } : {
                    backgroundColor: getBackgroundColor(step),
                    height: '100%',
                    width: '50%',
                }}
            >
                {step > 1 && <ChangeStepButton
                    position='up'
                    text={stepName(step === 4 ? shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step - 1 : step - 2 : step - 2 : step - 1)}
                    isPortrait={isPortrait}
                    onClick={() => step === 4 ? shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? changeStep(step - 1) : changeStep(step - 2) : changeStep(step - 2) : changeStep(step - 1)}
                />}

                {step === 1 && <Fade bottom>
                    <Shape
                        step={step}
                        param={shape}
                        setParam={validParam}
                        isPortrait={isPortrait}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 2 && <Fade bottom>
                    <WoodOrigine
                        step={step}
                        shape={shape}
                        param={woodOrigine}
                        setParam={validParam}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 3 && <Fade bottom>
                    <WoodGrain
                        step={step}
                        param={woodGrain}
                        setParam={validParam}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 4 && <Fade bottom>
                    <Seasoning
                        step={shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step : step - 1 : step - 1}
                        param={seasoning}
                        setParam={validParam}
                        isPortrait={isPortrait}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 5 && <Fade bottom>
                    <Toasting
                        step={shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step : step - 1 : step - 1}
                        param={toasting}
                        setParam={validParam}
                        oakToasting={oakToasting}
                        toastAndChar={toastAndChar}
                        isPortrait={isPortrait}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 6 && <Fade bottom>
                    <HeadToasting
                        step={shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step : step - 1 : step - 1}
                        param={headToasting}
                        setParam={validParam}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step === 7 && <Fade bottom>
                    <Finishing
                        step={shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step : step - 1 : step - 1}
                        hoopsInfos={shape === '250 L / 90 cm' || shape === '300 L' || shape === '350 L' || shape === '400 L' || shape === '500 L' ? [{value: '8'}] : [{value: '6'}, {value: '8'}]}
                        sanding={sanding}
                        setSanding={setSanding}
                        hoops={hoops}
                        setHoops={setHoops}
                        submit={submit}
                        isPortrait={isPortrait}
                        backgroundColor={getBackgroundColor(step)}
                    />
                </Fade>}

                {step < 7 && <ChangeStepButton
                    position='down'
                    text={stepName(step === 2 ? shape === '200 L' || shape === '225 L / 90 cm'|| shape === '225 L / 95 cm' || shape === '250 L / 90 cm' ? woodOrigine === 'FRENCH OAK' ? step + 1 : step + 2 : step + 2 : step + 1)}
                    isPortrait={isPortrait}
                    onClick={nextStep}
                />}

                {!isPortrait && <Fade top spy={step} appear>
                <div
                    id={styles.progressBar}
                    style={{height: 100/7*step + '%'}}
                >
                </div>
                </Fade>}
            </div>

            <MyBarrelLogo
                color='white'
                isPortrait={isPortrait}
            />
            <DemptosLogo
                color='white'
                isPortrait={isPortrait}
            />

            {showError && <Alert
                style={isPortrait ? {alignSelf: 'center', height: '10em', width: '20em'} : {alignSelf: 'center'}}
                text={'Please note, you haven’t chose any option, you can’t move to the next page.'}
                isPortrait={isPortrait}
                onClick={() => setShowError(false)}
            />}
        </div>
    )
};

export default BarrelConceptor;
