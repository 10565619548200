export const getBackgroundColor = (step) => {
    switch (step) {
        case 1:
            return '#858385';
            break;
        case 2:
            return '#C8BB9E';
            break;
        case 3:
            return '#82746F';
            break;
        case 4:
            return '#9EA29D';
            break;
        case 5:
            return '#A27F77';
            break;
        case 6:
            return '#BF7931';
            break;
        case 7:
            return '#B18E78';
            break;
        default:
            return '#858385';
    }
}
