import React, { useState } from 'react';

import TextInput from '../../ui/TextInput';
import Button from '../../ui/Button';

import styles from './style.module.css';
import DemptosLogoWhite from '../../../assets/images/logo_demptos_blanc.png';
import MyBarrelLogoWhite from '../../../assets/images/logo_design_my_barrel_blanc.png';

const AdminLogin = ({ setLogin }) => {
  const [loginPassword, setLoginPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const adminLogin = (e) => {
      e.preventDefault();
      setShowError(false);
      if (loginPassword === 'DMBDemptos2020?') {
          setLogin(true);
      } else {
          setShowError(true);
      }
  }

  return (
    <div id={styles.container}>
        <img
            id={styles.demptosLogo}
            src={DemptosLogoWhite}
            alt="Demptos"
        />

        <img
            id={styles.myBarrelLogo}
            src={MyBarrelLogoWhite}
            alt="Design my Barrel"
        />

        <p id={styles.title}>ADMIN SPACE</p>

        <form id={styles.form} onSubmit={adminLogin}>
            <TextInput
                name="code"
                placeholder="Password"
                onChange={(event) => setLoginPassword(event.target.value)}
                password
            />

            <div id={styles.errorContainer}>
                {showError && <p className={styles.errorText}>
                    Your code doesn't exist, please retry or <a className={styles.errorText} href="mailto:contact@demptos.fr" style={{fontSize: '1em', fontWeight: 'bold'}}>contact us.</a>
                </p>}
            </div>


            <Button
                type="submit"
                value="ADMIN ACCESS"
            />

        </form>
    </div>
  )
};

export default AdminLogin;