import React, { useState } from 'react';
import styles from './ChangeStepButton.module.css';
import Pulse from 'react-reveal/Pulse';
import Arrow from '../../assets/icons/arrow_white.png';

const ChangeStepButton = (props) => {
    const { position, text, isPortrait, onClick } = props;

    const [animation, setAnimation] = useState(false);

    if (position === 'up') {
        return (
            <button
                id={styles.container}
                style={isPortrait ? {top: 'inherit', bottom: '2em', left: '3em', display: 'flex', alignItems: 'center'} : null}
                onMouseEnter={() => setAnimation(true)}
                onMouseLeave={() => setAnimation(false)}
                onClick={onClick}
            >
                <img
                    id={styles.img}
                    style={isPortrait ? {transform: 'rotate(-90deg)', height: '0.4em', marginRight: '10px'} : {marginBottom: '10px'}}
                    src={Arrow}
                    alt="Previous Step"
                />
                <Pulse when={animation}>
                    <p id={styles.text}>{text}</p>
                </Pulse>
            </button>
        )
    }

    return (
        <button
            id={styles.container}
            style={isPortrait ? {top: 'inherit', bottom: '2em', right: '3em', display: 'flex', alignItems: 'center'} : {top: 'inherit', bottom: '1em'}}
            onMouseEnter={() => setAnimation(true)}
            onMouseLeave={() => setAnimation(false)}
            onClick={onClick}
        >
            <Pulse when={animation}>
                <p id={styles.text}>{text}</p>
            </Pulse>
            <img
                id={styles.img}
                style={isPortrait ? {transform: 'rotate(90deg)', height: '0.4em', marginLeft: '10px'} : {transform: 'rotate(180deg)', marginTop: '10px'}}
                src={Arrow}
                alt="Next Step"
            />
        </button>
    )
};

export default ChangeStepButton;
