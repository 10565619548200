import React from 'react';
import { displayImg } from '../../utils/displayImg';

const BackgroundImage = (props) => {
    const { step } = props;

    return (
        <img
            src={displayImg(step)}
            alt="Design my Barrel"
        />
    )
}

export default BackgroundImage;
