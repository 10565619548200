import React from 'react';
import styles from './Steps.module.css';
import Fade from 'react-reveal/Fade';
import ParamButton from '../ui/ParamButton';

const WoodGrain = (props) => {
    const { step, param, setParam, backgroundColor } = props;

    const infos = [
        {value: 'TIGHT', info: 'Highest permeability to oxygen. Powerful aroma impact.'},
        {value: 'MEDIUM', info: 'Intermediate permeability to oxygen. Powerful aroma impact.'},
        {value: 'OPEN', info: 'Lowest permeability to oxygen. More structure than aroma impact.'},
    ];

    return (
        <div id={styles.container}>
            <p id={styles.step}>{'0' + step}</p>
            <p id={styles.title}>WOOD GRAIN</p>

            {infos.map((info, key) => (
                <Fade key={key} delay={300 + key * 100}>
                    <ParamButton
                        style={param === info.value ? {backgroundColor: 'white', color: '#82746F'} : null}
                        backgroundColor={backgroundColor}
                        text={info.value}
                        info={info.info}
                        selected={param === info.value}
                        onPress={() => setParam('woodGrain', info.value)}
                    />
                </Fade>
            ))}
        </div>
    );
};

export default WoodGrain;
