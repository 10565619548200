import React, { useState } from 'react';
import styles from './ParamButton.module.css';
import { useMediaQuery } from 'react-responsive';

const ParamButton = (props) => {
    const { style, otherIsSelected, backgroundColor, step, text, info, selected, onPress, onMouse, offMouse } = props;

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const [show, setShow] = useState(false);

    return (
        <div id={styles.container} style={isPortrait ? text === 'TOAST&CHAR®' && otherIsSelected ? {flexDirection: 'column', marginTop: '4em', marginBottom: '0em'} : {flexDirection: 'column', marginBottom: '0em'} : null}>
            <input
                id={styles.button}
                style={show ? step ? step === 'TOASTING' ? {marginLeft: '3em', marginRight: '3em', color: backgroundColor} : {marginTop: '1em', marginBottom: '1em', color: backgroundColor} : {color: backgroundColor} : style}
                type="button"
                value={text}
                onMouseEnter={onMouse ? onMouse : () => setShow(true)}
                onMouseLeave={offMouse ? offMouse : () => setShow(false)}
                onClick={onPress}
            />

            {!isPortrait && show && <p id={styles.text} style={ text === '# 1' || text === '# 2' ? {alignSelf: 'center', width: '9em', marginLeft: '-10em', textAlign: 'right'} : {alignSelf: 'baseline'}}>{info}</p>}
            {isPortrait && <p id={styles.text} style={{position: 'static', marginLeft: '0em', textAlign: 'center', width: '80%', lineHeight: '15px'}}>{info}</p>}
        </div>
    )
};

export default ParamButton;
