import React from 'react';
import styles from './Alert.module.css';
import CloseIcon from '../../assets/icons/close_icon.png';

const Alert = (props) => {
    const { style, text, isPortrait, onClick } = props;

    return (
        <div id={styles.container} style={style}>
            <p id={styles.text}>{text}</p>
            <img src={CloseIcon} alt={'Close'} onClick={onClick}/>
        </div>
    )
}

export default Alert;
